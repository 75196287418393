.App {
  text-align: left;
}

.section {
  display: flex;
  flex-direction: row;
  height: 400px;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 10%;
}

.section-text {
  float: left;
  margin-left: 10%;
  width: 40%;
}

.picture {
  pointer-events: none;
  float: left;
  margin-left: 8%;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.picture img {
  width: 100%;
}

.picture p {
  font-size: 10px;
  color: rgb(112, 116, 119); 
}

.picture a {
  color: rgb(112, 116, 119);
}

.ResumeButton {
  background-color: rgb(0, 106, 255);
  border: none;
  height: 50px;
  width: 200px;
  position: fixed;
  bottom: 5%;
  right: 5%;
  border-radius: 30px;
  color: white;
  font-weight: bold;
}

.ResumeButton:hover {
  background-color: rgb(54, 136, 250);
}

@media (prefers-reduced-motion: no-preference) {
  .ease-in {
    animation: ease-in-animation 1s linear;
  }
}

@keyframes ease-in-animation {
  from {
    transform: translateY(-3%);
    opacity: 0.8;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

hr {
  margin-top: 10%;
  border: 0.2px solid #FAF9F6;
}

.App-link {
  color: #61dafb;
}
